<template>
  <transition name="notification">
    <div id="notification" v-if="show" :class="{'s': type === 's' || type === '', 'w': type === 'w', 'd': type === 'd'}">
      <div class="icon-container"></div>
      <p v-html="text"></p>
    </div>
  </transition>
</template>

<script>

export default {
  name: "Notification",
  props: {
    type: {type: String, default: ''},
    text: {type: String, default: ''}
  },
  data() {
    return {
      show: false
    }
  },
  watch: {
    text: function () {
      if (this.text !== '') {
        // console.log('NOTIFICATION!!!!!!')
        this.showNotification()
      } else {
        this.show = false
      }
    }
  },
  methods: {
    showNotification() {
      let delay = 0
      switch (this.type) {
        case '':
        case 's':
          delay = 5000
          break
        case 'w':
          delay = 6000
          break
        case 'd':
          delay = 8000
      }
      this.show = true
      setTimeout(() => {
        this.$store.dispatch('notify')
      }, delay)
    }
  }
}
</script>

<style scoped lang="scss" src="../assets/scss/notification.scss"></style>
