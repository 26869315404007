import axios from 'axios';

const clientURL = {
    development: 'http://localhost:55550/',
    production: 'https://kolomafamily.com/'
}

function setPostData(data) {
    if (data == null) return ''
    if (typeof data === 'string') return data
    else return JSON.stringify(data)
}

function getApiData(dataPath, methodIsPOST, postData) {
    // console.info('API DATA REQUEST: ', dataPath, methodIsPOST, postData, isFormData)
    const apiUrl = clientURL[process.env.NODE_ENV] + 'get-data/'+ dataPath.replace(/\//g, '+')
    if (!methodIsPOST) return axios.get(apiUrl)
    else return axios.post(apiUrl, setPostData(postData), {responseType: 'json'})
}

function getImageSrc(image, dir) {
    return window.location.origin + '/' + dir + '/' + image
}

function getPage(pagename) {
    return getApiData(`pages/${pagename}`)
}

function getNovaAreas() {
    return axios.get(clientURL[process.env.NODE_ENV] + 'get/areas/np')
}

function getNovaCitiesByRef(ref) {
    return axios.get(clientURL[process.env.NODE_ENV] + 'get/cities/ref/' + ref)
}

function getNovaCitiesByName(name) {
    return axios.get(clientURL[process.env.NODE_ENV] + 'get/cities/search/' + name)
}

function getWarehouses(city) {
    return axios.get(clientURL[process.env.NODE_ENV] + 'get/wh/' + city)
}

function getPostomats(city) {
    return axios.get(clientURL[process.env.NODE_ENV] + 'get/pm/' + city)
}

const api = {
    getSrc: getImageSrc,
    getData: getApiData,
    getPage: getPage,
    getNovaAreas: getNovaAreas,
    getNovaCitiesByRef: getNovaCitiesByRef,
    getNovaCitiesByName: getNovaCitiesByName,
    getWarehouses: getWarehouses,
    getPostomats: getPostomats
};

export default api;
