<template>
  <footer>
    <div class="inner">
      <div class="left">
<!--        <div class="kf-logo"><span><router-link to="/">KF</router-link></span></div>-->
        <span class="a">&#169; 2024 Koloma Family. All rights reserved.</span>
      </div>
      <div class="right">
        <span><router-link to="/policy">Privacy policy</router-link></span>
        <span><router-link to="/terms">Terms of use</router-link></span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
footer {
  .inner {
    justify-content: space-around;
    //gap: 40px;
    padding: 40px 0;

    > * {
      font-size: 1.2rem;
    }

    .kf-logo {
      width: 70px;
      height: 70px;
    }
    .left {
      justify-content: space-evenly;
    }
    .right {
      flex: 0!important;
      justify-content: flex-start!important;
    }
  }
}
</style>