<template>
  <div id="app">
    <Header></Header>
    <router-view/>
    <Footer></Footer>
    <div class="background" :class="{'whitened': $route.path !== '/'}">
      <img src="./assets/img/bg.jpg" alt="">
    </div>
    <button class="red coffee">
      <a target="_blank" href="https://www.buymeacoffee.com/kolomafamily"></a>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 256 256" enable-background="new 0 0 256 256" xml:space="preserve">
        <g><g><path fill="#FFFFFF" d="M242.7,130.4c-2.8-4.1-8.6-9.1-20.5-9.1h-13.9c0.2-8.8-0.4-14.6-0.4-14.6H15.7c0,0-4,36.9,12.5,65.9c10.3,18.1,16.6,23.2,25.4,32.2c14.9,15,32.8,15,32.8,15h50.5c0,0,17.9,0,32.6-14.5c3.4-3.4,6.5-6.3,9.6-9.7c50.6-2.8,63.5-38.2,65-42.9C245,150.1,248.6,139.2,242.7,130.4L242.7,130.4z M228,146.6l-0.3,0.9c-0.1,0.2-6.4,21.3-34.3,28.6c0.7-1.1,1.3-2.3,2.1-3.5c6.2-11.1,9.5-23.2,11.2-34.1h15.5c4.1,0,5.9,1,6.2,1.5C229.3,141.1,228.8,144.4,228,146.6L228,146.6z M212.9,232.3H11.3c-12.1,13.1,67,21.5,67,21.5h67.6C145.8,253.8,222.6,245.8,212.9,232.3 M129.8,91c0.2,0.7-2.2,1.6-3,1.3c-24.3-15.3-18.4-36.5-2.6-52.1c12.1-11.8,21.1-17.7,21.2-36.5c-0.1-1.7,3.6-1.9,4.2-1.2C179.5,44.9,112.6,47.1,129.8,91 M86,91c0.3,0.7-2.1,1.6-2.9,1.3C58.8,77,64.6,55.7,80.5,40.2c12-11.8,21.1-17.7,21.1-36.5c0-1.7,3.7-1.9,4.3-1.2C135.7,44.9,68.8,47.1,86,91"/></g></g>
      </svg>
      <span>Buy Me A Coffee!</span>
    </button>
    <button class="red chat" v-if="CONTACTS.telegram">
      <a target="_blank" :href="CONTACTS.telegram"></a>
      <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512"><path fill="#FFFFFF" fill-rule="nonzero" d="M256 0c70.69 0 134.69 28.655 181.018 74.982C483.345 121.31 512 185.31 512 256s-28.655 134.69-74.982 181.018C390.69 483.345 326.69 512 256 512s-134.69-28.655-181.018-74.982C28.655 390.69 0 326.69 0 256S28.655 121.31 74.982 74.982C121.31 28.655 185.31 0 256 0zm-49.371 316.575c-.992-1.286 2.594-10.118 3.443-11.546-9.722-8.651-17.404-17.379-19.041-35.34l-1.043.022c-2.408-.032-4.729-.586-6.903-1.825-3.481-1.979-5.93-5.379-7.583-9.212-3.5-8.043-15.031-34.738 2.537-32.628-9.823-18.345 12.409-49.684-25.935-61.275 31.46-39.845 97.839-101.281 146.483-39.654 53.245 5.16 69.853 68.437 34 103.093 2.101.076 4.08.56 5.832 1.498 6.665 3.57 6.884 11.318 5.132 17.819-1.733 5.429-3.934 9.104-6.01 14.397-2.524 7.147-6.215 8.478-13.345 7.708-.362 17.67-8.528 26.343-19.518 36.724l3.007 10.187c-14.737 31.261-75.957 32.518-101.056.032zM78.752 394.224c12.076-51.533 45.656-33.396 110.338-73.867 22.982 47.952 116.386 51.437 135.54 0 55.35 35.384 98.967 20.923 109.958 72.138 28.965-37.841 46.176-85.158 46.176-136.495 0-62.068-25.158-118.26-65.83-158.934C374.26 56.394 318.068 31.236 256 31.236S137.74 56.394 97.066 97.066C56.394 137.74 31.236 193.932 31.236 256c0 52.123 17.744 100.099 47.516 138.224z"/></svg>
      <span>Let's Chat!</span>
    </button>
    <Notification :type="$store.getters.NOTIFICATION.type" :text="$store.getters.NOTIFICATION.text"></Notification>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Header from "./components/Header";
import Footer from "./components/Footer";
import Notification from "./components/Notification";
import api from "./api/api";

export default {
  name: "App",
  components: {Header,Footer,Notification},
  computed: {
    ...mapGetters([
        'CONTACTS'
    ])
  },
  beforeMount() {
    const lang = navigator.language.toLowerCase()
    if (lang.indexOf('ua') < 0 && lang.indexOf('ru') < 0 && lang.indexOf('be') < 0) this.$store.dispatch('changeLang')
    api.getData('get/c').then(r => {
      if (!r.data.error) {
        this.$store.dispatch('addContacts', r.data.contacts)
      }
    })
  },
}
</script>

<style lang="scss" src="./assets/scss/reset.scss"></style>
<style lang="scss" src="./assets/scss/app.scss"></style>
