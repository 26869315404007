import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: 'ua',
    contacts: {email: '', phone: '', facebook: '', instagram: '', tiktok: '', telegram: ''},
    notification: {type: '', text: ''},
  },
  getters: {
    LANG: state => { return state.lang },
    NOTIFICATION: state => { return state.notification },
    CONTACTS: state => { return state.contacts }
  },
  mutations: {
    notify(state, payload) {
      state.notification.type = payload ? payload.type || '' : ''
      state.notification.text = payload ? payload.text || '' : ''
    }
  },
  actions: {
    changeLang(context) {
      context.state.lang = context.state.lang === 'ua' ? 'en' : 'ua'
    },
    addContacts(context, payload) {
      context.state.contacts = payload
    },
    notify(context, payload) {
      context.commit('notify', payload)
    }
  },
  modules: {
  }
})
